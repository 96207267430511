
















































import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { BusyObject } from '@/models/Busy';
import { BrandQueryResult, StoredBrandQuery as StoredBrandQueryData, BrandQueriesService, StoredBrandQueriesService } from '@/api/braendz';

import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue';
import HorizontalBrandTileGrid from '@/components/Brands/HorizontalBrandTileGrid.vue';
import BrandTable from '@/components/Brands/BrandTable.vue';

@Component({
    components: {
        VerticalBrandTileGrid,
        HorizontalBrandTileGrid,
        BrandTable
    },
    metaInfo() {
        return {
            title: this.$i18n.t("storedBrandQueryResultView.title", { agentName: (this as unknown as StoredBrandQueryResult)?.storedBrandQuery.object?.name ?? this.$i18n.t('storedBrandQueryResultView.agentNameLoading') }).toString(),
        };
    }
})
export default class StoredBrandQueryResult extends Vue {

    // Properties:
    public activeQueryResultView = "vertical-view";

    public brandQueryResult = new BusyObject<BrandQueryResult>();
    public storedBrandQuery = new BusyObject<StoredBrandQueryData>();
    
    // Getter:
    public get storedBrandQueryId(): number {
        return parseInt(this.$route.params?.queryId);
    }

    public get resultId(): string | null {
        return this.$route.params?.resultId;
    }

    // Lifecycle Methods:
    public mounted(): void {
        this.refreshBrandQueryResult();
        this.refreshStoredBrandQuery();
    }

    // Methods:
    public async refreshBrandQueryResult(): Promise<void> {
        await this.brandQueryResult.load(async () => {
            if(this.resultId) {
                return await BrandQueriesService.getBrandQueryHistoryItemResult(this.resultId);
            } else {
                return null;
            }
        });
    }
    public async refreshStoredBrandQuery(): Promise<void> {
        await this.storedBrandQuery.load(async () => {
            if(isNaN(this.storedBrandQueryId)) {
                return null;
            }
            else {
                return await StoredBrandQueriesService.getStoredBrandQuery(this.storedBrandQueryId);
            }
        });
    }
}






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueryResultItem, Brand } from '@/api/braendz';
import { DataTableHeader } from 'vuetify';

@Component({})
export default class BrandTable extends Vue {

    // Component Properties:
    @Prop({ required: true })
    public brands!: (BrandQueryResultItem | Brand)[];

    public headers = [
        { text: this.$t('brand.table.registrationOfficeCode'), align: 'start', value: 'registrationOfficeCode.shortDescription', groupable: false },
        { text: this.$t('brand.table.image'), align: 'start', value: 'brandLogoUrl', groupable: false },
        { text: this.$t('brand.table.name'), value: 'name', groupable: false },
        { text: this.$t('brand.table.registrationNumber'), value: 'registrationNumber' },
        { text: this.$t('brand.table.applicationNumber'), value: 'applicationNumber' },
        { text: this.$t('brand.table.brandType'), value: 'brandType.description' },
        { text: this.$t('brand.table.state'), value: 'brandState.description' },
        { text: this.$t('brand.table.registrationDate'), value: 'registrationDate' },
        { text: this.$t('brand.table.applicationDate'), value: 'applicationDate' },
        { text: this.$t('brand.table.oppositionPeriodEndDate'), value: 'oppositionPeriodEndDate' },
        { text: this.$t('brand.table.expiryDate'), value: 'expiryDate' }
    ] as DataTableHeader[];
}
